// @flow

import * as React from 'react'
import styled from 'styled-components'

import { CookieDisclaimerContext } from '../context/CookieDisclaimerContext'
import CookieDisclaimerDetails from './CookieDisclaimerDetails'
import CookieDisclaimerParagraph from './CookieDisclaimerParagraph'
import CookieDisclaimerSlider from './CookieDisclaimerSlider'

import type { CookieDisclaimerCategoryViewModel } from '../types/CookieDisclaimerCategoryViewModel'
import CookieDisclaimerLinkButton from './CookieDisclaimerLinkButton'

const Wrapper = styled.section``

const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`

const Title = styled.h3`
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  letter-spacing: normal;
  line-height: 1.78;
  margin: 0;
`

const Text = styled.div`
  margin-bottom: 20px;
`

const DetailsWrapper = styled.div`
  margin-bottom: 28px;
  margin-top: 28px;
`

type Props = {
  onCategoryChange: (selected: boolean) => void,
} & CookieDisclaimerCategoryViewModel

const CookieDisclaimerCategory = ({
  necessary,
  title,
  text,
  details,
  selected,
  onCategoryChange,
}: Props) => {
  const [showDetails, setShowDetails] = React.useState(false)
  const { colorTheme, hideDetailsText, showDetailsText } = React.useContext(
    CookieDisclaimerContext,
  )

  return (
    <Wrapper>
      <Header>
        <Title colorTheme={colorTheme}>{title}</Title>
        <CookieDisclaimerSlider
          checked={selected || false}
          disabled={necessary || false}
          colorTheme={colorTheme}
          onChange={checked => {
            onCategoryChange(checked)
          }}
        />
      </Header>
      {text && (
        <Text>
          <CookieDisclaimerParagraph noMargin text={text} />
        </Text>
      )}
      {details && (
        <>
          {showDetails && (
            <DetailsWrapper>
              <CookieDisclaimerDetails details={details} />
            </DetailsWrapper>
          )}
          <CookieDisclaimerLinkButton
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? hideDetailsText : showDetailsText}
          </CookieDisclaimerLinkButton>
        </>
      )}
    </Wrapper>
  )
}

export default CookieDisclaimerCategory
