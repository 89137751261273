// @flow
import scriptLoader from './script-loader'

export type GtagEvent = {
  eventName: string,
  category?: string,
  label?: string,
}

/**
 * Wrap the global "gtag" method, so we can ensure it actually exists before calling it.
 **/
function gtag(event: string, key: any, values?: any) {
  if (global.dataLayer) {
    global.dataLayer.push(arguments)
  }
}

/**
 * Init gtag.js, based on the embed code
 * https://developers.google.com/analytics/devguides/collection/gtagjs/
 **/
export async function initGTAG(key: string, options: Object = {}) {
  global.dataLayer = global.dataLayer || []
  global.gtag = gtag

  gtag('js', new Date())
  gtag('config', key, {
    // Use beacons if available
    transport_type: navigator.sendBeacon ? 'beacon' : 'xhr',
    ...options,
  })
  gtag('gtm.start', new Date().getTime())

  // Delay loading the analytics script until the browser is idle, i.e. all other critical assets have been loaded.
  try {
    await scriptLoader(`https://www.googletagmanager.com/gtm.js?id=${key}`)
  } catch (error) {}
}

/**
 * Throw an exception
 */
export function exception(description: string, fatal: boolean = false) {
  gtag('event', 'exception', {
    description,
    fatal,
  })
}

/**
 * Track an event
 * Supply a callback, that gets triggered after the event is tracked
 */
export function trackEvent(
  { eventName, category, label, ...rest }: GtagEvent,
  callback?: () => void,
) {
  const keys = {
    event_category: category,
    event_label: label,
    ...rest,
  }

  if (!callback) {
    gtag('event', eventName, keys)
  } else {
    const cb = createCallback(callback)

    gtag('event', eventName, {
      ...keys,
      event_callback: cb,
    })

    // If using beacons - trigger callback now
    if (!cb) callback()
  }
}

function createCallback(callback: () => void): ?Function {
  if (!callback || usingBeacons()) return undefined
  let safetyCallbackCalled = false

  const safetyCallback = () => {
    safetyCallbackCalled = true
    callback()
  }

  // Using a timeout to ensure the execution of critical application code, in case
  // the GA server might be down or an ad blocker prevents sending the data
  const t = setTimeout(safetyCallback, 250)

  return () => {
    clearTimeout(t)
    if (!safetyCallbackCalled) {
      callback()
    }
  }
}

export function timing(name: string, category: ?string = undefined) {
  // Feature detects Navigation Timing API support.
  if (window.performance) {
    // Gets the number of milliseconds since page load
    // (and rounds the result since the value must be an integer).
    const timeSincePageLoad = Math.round(performance.now())

    // Sends the timing event to Google Analytics.
    gtag('event', 'timing_complete', {
      name,
      value: timeSincePageLoad,
      event_category: category,
    })
  }
}

export function usingBeacons(): boolean {
  return !!navigator.sendBeacon
}

export default gtag
