// @flow

import * as React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

import { CookieDisclaimerContext } from '../context/CookieDisclaimerContext'

const Element = styled(ReactMarkdown)`
  && p {
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.75;

    ${({ noMargin }) => `
      margin-bottom: ${noMargin ? '0' : '24px'};
      margin-top: ${noMargin ? '0' : '20px'};
    `}

    a {
      text-decoration: underline;

      ${({ theme }) => `
        color: ${theme.primary}
      `}

      &:hover {
        color: ${({ theme }) => theme.logoTextColorHover};
      }

      &:focus {
        box-shadow: 0 0 2px 2px #51a7e8;
        outline: none;
      }
    }
  }
`

type Props = {
  noMargin?: boolean,
  text?: string,
}

const CookieDisclaimerParagraph = ({ text, noMargin }: Props) => {
  const { colorTheme } = React.useContext(CookieDisclaimerContext)

  return (
    colorTheme && (
      <Element
        colorTheme={colorTheme}
        source={text}
        linkTarget="_blank"
        noMargin={noMargin}
      />
    )
  )
}

CookieDisclaimerParagraph.displayName = 'CookieDisclaimerParagraph'
CookieDisclaimerParagraph.defaultProps = {}

export default CookieDisclaimerParagraph
