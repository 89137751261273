// @flow
import * as React from 'react'

import { CookieDisclaimerContext } from '../context/CookieDisclaimerContext'
import Button from '../../Button/Button'

import type { CookieDisclaimerButtonTypes } from '../types/enums/CookieDisclaimerButtonTypes'

type Props = {
  type: CookieDisclaimerButtonTypes,
}

const CookieDisclaimerButton = ({ type, ...rest }: Props) => {
  const { colorTheme } = React.useContext(CookieDisclaimerContext)
  return (
    <Button
      className="CookieDisclaimerButton"
      type={'normal'}
      colorTheme={colorTheme}
      {...rest}
    />
  )
}

CookieDisclaimerButton.displayName = 'CookieDisclaimerButton'
CookieDisclaimerButton.defaultProps = {}

export default CookieDisclaimerButton
