// @flow
import * as React from 'react'
import { useContext } from 'react'
import root from 'window-or-global'

import {
  getLocalStorageAccepted,
  getLocalStorageAmount,
  getLocalStorageTimestamp,
  getLocalStorageType,
  initLocalStorage,
  setLocalStorageAccepted,
} from './utils/localStorage'
import { initGTAG } from '../../utils/gtag'
import createColorTheme from './utils/createColorTheme'
import getCategoriesHasSelected from './utils/getCategoriesHasSelected'

import { CookieDisclaimerContextProvider } from './context/CookieDisclaimerContext'
import ContextProviderClient from './ContextProviderClient'
import { ConfigContext } from '../../App/AppShell'

import type { CookieDisclaimerViewModel } from './types/CookieDisclaimerViewModel'
import type { CookieDisclaimerContextViewModel } from './types/CookieDisclaimerContextViewModel'
import { useAtom } from 'jotai'
import { showCookieDisclaimerAtom } from '../../App/Atoms'

type Props = {
  onComplete?: () => void,
  onDismiss?: () => void,
  logo?: (props: any) => React.Node,
} & CookieDisclaimerViewModel

const CookieDisclaimer = (props: Props) => {
  const { onComplete, onDismiss, logo, ...rest } = props
  const [showCookie, setShowCookie] = useAtom(showCookieDisclaimerAtom)
  const { googleTagManagerId } = useContext(ConfigContext)
  const [state, setState] = React.useState<CookieDisclaimerContextViewModel>({
    colorTheme: createColorTheme(rest.colorTheme),
    ...rest,
    dispatch: () => {},
  })
  const lastUpdatedTimestamp: string = state.lastUpdatedTimestamp || ''

  React.useEffect(() => {
    let categories: any = state.categories
    const isAccepted = getLocalStorageAccepted()
    const selectedType = getLocalStorageType()

    const numCmsCookies = categories
      ? categories.reduce((prev, curr) => {
          return prev + curr.details.length
        }, 0)
      : 0
    const numLocalStorageObjects = getLocalStorageAmount()
    const browserCookiesMatchCmsData = numCmsCookies === numLocalStorageObjects

    const cookieAcceptedTimestamp = getLocalStorageTimestamp()
    const hasNewCmsCookieData =
      +lastUpdatedTimestamp > cookieAcceptedTimestamp ? true : false

    if (!isAccepted || !browserCookiesMatchCmsData || hasNewCmsCookieData) {
      setShowCookie(true)
      root.document.body.style.overflow = 'hidden'
    } else {
      initLocalStorage(state.categories)

      if (googleTagManagerId) {
        if (selectedType === 'selected' || selectedType === 'all') {
          initGTAG(googleTagManagerId)
        }
      }

      setState({
        ...state,
        categories,
      })
      setShowCookie(false)
    }
  }, [])

  return showCookie ? (
    <CookieDisclaimerContextProvider
      value={state}
      dispatch={newContext => {
        setState({
          ...state,
          ...newContext,
        })
      }}
    >
      <ContextProviderClient
        open={showCookie}
        onChange={categories => {
          const categoriesHasSelected = getCategoriesHasSelected(categories)

          setState({
            ...state,
            categories,
            selectionChoice: categoriesHasSelected,
          })
        }}
        onComplete={(type, categories) => {
          initLocalStorage(state.categories)
          setLocalStorageAccepted(true, lastUpdatedTimestamp, type)

          if (type === 'selected' || type === 'all') {
            if (googleTagManagerId) {
              initGTAG(googleTagManagerId)
            }
          }

          setShowCookie(false)
          if (onComplete) onComplete()
        }}
        onDismiss={onDismiss}
        logo={logo}
      />
    </CookieDisclaimerContextProvider>
  ) : null
}

CookieDisclaimer.displayName = 'CookieDisclaimer'
CookieDisclaimer.defaultProps = {}

export default CookieDisclaimer
