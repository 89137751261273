// @flow
import * as React from 'react'
import styled from 'styled-components'

import { CookieDisclaimerContext } from '../context/CookieDisclaimerContext'
import CookieDisclaimerParagraph from './CookieDisclaimerParagraph'

import type { CookieDisclaimerContextViewModel } from '../types/CookieDisclaimerContextViewModel'
import type { CookieDisclaimerDetailsEntryViewModel } from '../types/CookieDisclaimerDetailsEntryViewModel'

const Wrapper = styled.section`
  align-items: center;
  display: flex;
  padding-bottom: 20px;
  padding-top: 20px;

  ${({ colorTheme }: Context) => `
    border-top: 1px solid ${colorTheme.border};

    &:last-child {
      border-bottom: 1px solid ${colorTheme.border};
    }
  `}
`

const Term = styled.dt`
  flex-basis: 50%;
  font-weight: 700;
`

const Description = styled.dd`
  flex-basis: 50%;
`

const Text = styled(CookieDisclaimerParagraph)``

type Context = CookieDisclaimerContextViewModel

type Props = CookieDisclaimerDetailsEntryViewModel

const CookieDisclaimerDetailsEntry = ({ title, text }: Props) => {
  const { colorTheme } = React.useContext(CookieDisclaimerContext)

  return (
    <Wrapper colorTheme={colorTheme}>
      <Term>{title}</Term>
      <Description>
        <Text text={text} noMargin />
      </Description>
    </Wrapper>
  )
}

CookieDisclaimerDetailsEntry.displayName = 'CookieDisclaimerDetailsEntry'
CookieDisclaimerDetailsEntry.defaultProps = {}

export default CookieDisclaimerDetailsEntry
