// @flow
import * as React from 'react'
import styled from 'styled-components'
import useId, { IdProvider } from '@charlietango/use-id'

import { CookieDisclaimerContext } from '../context/CookieDisclaimerContext'

import type { CookieDisclaimerContextViewModel } from '../types/CookieDisclaimerContextViewModel'
import type { ThemeViewModel } from '../../../types/ThemeViewModel'

const Wrapper = styled.div`
  position: relative;
`

const SliderLabel = styled.label`
  display: block;
  position: relative;
  border-radius: 18px;
  cursor: pointer;
  overflow: hidden;
  width: 62px;
  height: 35px;
  padding: 5px;

  ${({ checked, theme }: SliderProps) => `
    ${
      checked
        ? `
      background-color: ${theme.cookieSliderActive};
    `
        : `
      background-color: ${theme.cookieSliderNormal};
    `
    }

    &:focus-within .SliderActiveDot {
      ${
        checked
          ? `
        background-color: ${theme.cookieSliderActive};
      `
          : `
        background-color: ${theme.cookieSliderNormal};
      `
      }
    }

    .focus-visible + div > .SliderActiveDot {
      ${
        checked
          ? `
        background-color: ${theme.cookieSliderActive};
      `
          : `
        background-color: ${theme.cookieSliderNormal};
      `
      }
    }
  `}
`

const SliderInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const SliderButton = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 100%;
  display: flex;
  height: 25px;
  justify-content: center;
  transition: transform 250ms ease-in-out;
  width: 25px;

  ${({ checked }: SliderProps) => `
    transform: ${checked ? 'translateX(27px)' : 'translateX(0)'};
  `}
`

const SliderActiveDot = styled.div`
  background-color: transparent;
  border-radius: 100%;
  height: 70%;
  width: 70%;
`

type SliderProps = {
  checked: boolean,
  colorTheme: $PropertyType<CookieDisclaimerContextViewModel, 'colorTheme'>,
  disabled: boolean,
  theme: ThemeViewModel,
}

type Props = {
  checked: boolean,
  disabled: boolean,
  onChange: (checked: boolean) => void,
}

const CookieDisclaimerSlider = ({ checked, disabled, onChange }: Props) => {
  const id = useId('CookieDisclaimerSlider')

  const { colorTheme } = React.useContext(CookieDisclaimerContext)

  return (
    !disabled && (
      <IdProvider>
        <Wrapper>
          <SliderLabel
            checked={checked}
            htmlFor={id}
            disabled={disabled}
            colorTheme={colorTheme}
            tabIndex={-1}
          >
            <SliderInput
              type="checkbox"
              id={id}
              disabled={disabled}
              checked={checked}
              aria-describedby={`slider-desc-${id}`}
              onChange={(event: SyntheticInputEvent<HTMLInputElement>) => {
                const { currentTarget } = event
                onChange(currentTarget.checked)
              }}
            />
            <SliderButton checked={checked}>
              <SliderActiveDot className="SliderActiveDot" />
            </SliderButton>
          </SliderLabel>
        </Wrapper>
      </IdProvider>
    )
  )
}

CookieDisclaimerSlider.displayName = 'CookieDisclaimerSlider'
CookieDisclaimerSlider.defaultProps = {}

export default CookieDisclaimerSlider
