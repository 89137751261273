// @flow
import * as React from 'react'
import styled, { withTheme } from 'styled-components'
import root from 'window-or-global'
import { color } from '../../styles/theme'

import CookieDisclaimer from '../../components/CookieDisclaimer/CookieDisclaimer'

import type { CookieDisclaimerViewModel } from '../../components/CookieDisclaimer/types/CookieDisclaimerViewModel'
import type { CookieDisclaimerColorThemeViewModel } from '../../components/CookieDisclaimer/types/CookieDisclaimerColorThemeViewModel'

import logo from './images/cookie-logo.svg'

const Logo = styled('img')`
  height: 32px;
`

type State = {
  overflow: string,
}

class CookieDisclaimerModule extends React.Component<
  CookieDisclaimerViewModel,
  State,
> {
  static displayName = 'CookieDisclaimerModule'
  static defaultProps = {}

  constructor(props: CookieDisclaimerViewModel) {
    super()

    this.state = {
      overflow:
        root && root.document ? root.document.body.style.overflowY : 'scroll',
    }
  }

  handleDismiss = () => {
    this.handleComplete()
  }

  handleComplete = () => {
    const { overflow } = this.state

    if (root && root.document) {
      root.document.body.style.overflow = overflow ? overflow : ''
    }
  }

  createColorTheme = (options?: CookieDisclaimerColorThemeViewModel) => {
    let theme: CookieDisclaimerColorThemeViewModel = {
      background: color.white,
      border: 'rgba(2, 58, 81, 0.16)',
      footer: color.mystic,
      text: color.prussianBlue,
      textdimmed: '#023a51',
      field: {
        normal: color.heather,
        active: color.fieldActive,
        selected: color.fieldActive,
      },
      button: {
        primary: {
          normal: 'transparent',
          inverted: color.keppel,
          normalText: color.keppel,
          invertedText: color.white,
        },
        normal: {
          normal: 'transparent',
          inverted: color.prussianBlue,
          normalText: color.prussianBlue,
          invertedText: color.white,
        },
      },
    }

    if (options) {
      theme = { ...theme, ...options }
    }

    return theme
  }

  render() {
    const { props } = this

    const colorTheme = this.createColorTheme(props.colorTheme)

    return (
      <CookieDisclaimer
        {...props}
        colorTheme={colorTheme}
        onComplete={this.handleComplete}
        onDismiss={this.handleDismiss}
        logo={() => <Logo src={logo} />}
      />
    )
  }
}

export default withTheme(CookieDisclaimerModule)
