// @flow
import { css } from 'styled-components'

const createHorizontalPaddingCss = css`
  padding-left: 16px;
  padding-right: 16px;

  @media only screen and (max-width: 1024px) and (min-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media only screen and (max-width: 1200px) and (min-width: 1024px) {
    padding-left: 72px;
    padding-right: 72px;
  }

  @media only screen and (min-width: 1200px) {
    padding-left: 99px;
    padding-right: 99px;
  }
`

export default createHorizontalPaddingCss
