// @flow

import * as React from 'react'
import styled from 'styled-components'
import { mediaOnly } from '../../../styles/media'

import { CookieDisclaimerContext } from '../context/CookieDisclaimerContext'
import CookieDisclaimerButton from '../components/CookieDisclaimerButton'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;

  ${mediaOnly.xs`
    align-items: inherit;
    flex-direction: column;
    justify-content: space-evenly;
  `};

  & > .CookieDisclaimerButton:first-child {
    margin-right: 1.5rem;

    ${mediaOnly.xs`
      margin-bottom: 0.75rem;
      margin-right: 0;
    `};
  }
`

type Props = {
  selectionChoice?: boolean,
  onButtonClick: (type: string) => void,
}

const CookieDisclaimerFooter = ({ selectionChoice, onButtonClick }: Props) => {
  const {
    allButtonText,
    allButtonPrimary,
    selectedButtonText,
    selectedButtonPrimary,
    necessaryButtonText,
    necessaryButtonPrimary,
  } = React.useContext(CookieDisclaimerContext)

  return (
    <Wrapper className="CookieDisclaimerFooter">
      <CookieDisclaimerButton
        type={allButtonPrimary ? 'primary' : 'normal'}
        onClick={() => {
          onButtonClick('all')
        }}
      >
        {allButtonText}
      </CookieDisclaimerButton>
      {selectionChoice ? (
        <CookieDisclaimerButton
          type={selectedButtonPrimary ? 'primary' : 'normal'}
          onClick={() => {
            onButtonClick('selected')
          }}
        >
          {selectedButtonText}
        </CookieDisclaimerButton>
      ) : (
        <CookieDisclaimerButton
          type={necessaryButtonPrimary ? 'primary' : 'normal'}
          onClick={() => {
            onButtonClick('necessary')
          }}
        >
          {necessaryButtonText}
        </CookieDisclaimerButton>
      )}
    </Wrapper>
  )
}

CookieDisclaimerFooter.displayName = 'CookieDisclaimerFooter'
CookieDisclaimerFooter.defaultProps = {}

export default CookieDisclaimerFooter
