// @flow
import * as React from 'react'

import createColorTheme from '../utils/createColorTheme'

import type { CookieDisclaimerContextViewModel } from '../types/CookieDisclaimerContextViewModel'
import type { CookieDisclaimerViewModel } from '../types/CookieDisclaimerViewModel'

const CookieDisclaimerContext = React.createContext<CookieDisclaimerContextViewModel>(
  { colorTheme: createColorTheme(), dispatch: () => {} },
)

type ContextProviderProps = {
  children: React.Node,
  value: CookieDisclaimerViewModel,
  dispatch: (newContext: CookieDisclaimerViewModel) => void,
}

const CookieDisclaimerContextProvider = ({
  children,
  value,
}: ContextProviderProps) => {
  return (
    // $FlowFixMe - Mismatch between context value and value
    <CookieDisclaimerContext.Provider value={value}>
      {children}
    </CookieDisclaimerContext.Provider>
  )
}

const CookieDisclaimerContextConsumer = CookieDisclaimerContext.Consumer

export {
  CookieDisclaimerContext,
  CookieDisclaimerContextProvider,
  CookieDisclaimerContextConsumer,
}
