// @flow

import root from 'window-or-global'

import type { CookieDisclaimerCookieManifestViewModel } from '../types/CookieDisclaimerCookieManifestViewModel'

const ACCEPTED_NAME = `CT_COOKIE_DISCLAIMER_ACCEPTED`
const ACCEPTED_TIME = `CT_COOKIE_DISCLAIMER_ACCEPTED_TIME`
const ACCEPTED_TYPE = `CT_COOKIE_DISCLAIMER_ACCEPTED_TYPE`
const COOKIE_AMOUNT = `CT_COOKIE_DISCLAIMER_AMOUNT`
const COOKIE_MANIFEST_NAME = `CT_COOKIE_DISCLAIMER_MANIFEST`

const localStorage = root.localStorage

export const getLocalStorageCookieManifest = (): CookieDisclaimerCookieManifestViewModel | null => {
  const localStorageValue = localStorage.getItem(COOKIE_MANIFEST_NAME)
  let returnValue = null

  try {
    returnValue = JSON.parse(localStorageValue)
  } catch (e) {}

  return returnValue
}

export const getLocalStorageAccepted = () => {
  const localStorageValue = localStorage.getItem(ACCEPTED_NAME)
  let returnValue: boolean = false

  if (localStorageValue === 'true') {
    returnValue = true
  }

  return returnValue
}

export const getLocalStorageTimestamp = () => {
  return +localStorage.getItem(ACCEPTED_TIME)
}

export const getLocalStorageType = () => {
  return localStorage.getItem(ACCEPTED_TYPE) || ''
}

export const getLocalStorageAmount = () => {
  return +localStorage.getItem(COOKIE_AMOUNT) || 0
}

export const setLocalStorageAccepted = (
  accepted: true | null,
  timestamp: string,
  type: string,
) => {
  localStorage.setItem(ACCEPTED_NAME, accepted)
  localStorage.setItem(ACCEPTED_TIME, timestamp)
  localStorage.setItem(ACCEPTED_TYPE, type)
}

export const initLocalStorage = (categories: any) => {
  localStorage.setItem(
    COOKIE_AMOUNT,
    categories.reduce((prev, curr) => {
      return prev + curr.details.length
    }, 0),
  )
}

export default localStorage
