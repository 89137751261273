// @flow
const SCRIPT_MAP = new Map()

/**
 * Load external script, and return a promise that resolves once the script is ready.
 * @param url
 * @returns {Promise}
 */
export default function loadScript(url: string): Promise<*> {
  if (!url) return Promise.reject(new Error('No URL supplied to script loader'))
  // Check if script is already loading/loaded
  let promise = SCRIPT_MAP.get(url)

  if (!promise) {
    // Create new Promise that creates a new script tag and sets the src to the URL
    promise = new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = url

      script.onload = () => resolve(url)
      script.onerror = () => reject(new Error(`Failed to load: ${script.src}`))
      if (document.head) {
        document.head.appendChild(script)
      }
    })

    SCRIPT_MAP.set(url, promise)
  }

  return promise
}
