// @flow
import * as React from 'react'
import styled from 'styled-components'
import useId from '@charlietango/use-id'
import useFocusTrap from '@charlietango/use-focus-trap'
import { mediaOnly } from '../../styles/media'

import { CookieDisclaimerContext } from './context/CookieDisclaimerContext'
import CookieDisclaimerBody from './containers/CookieDisclaimerBody'
import CookieDisclaimerFooter from './containers/CookieDisclaimerFooter'
import CookieDisclaimerHeader from './containers/CookieDisclaimerHeader'

import createCssHorizontalPadding from './utils/createCssHorizontalPadding'

import type { CookieDisclaimerContextViewModel } from './types/CookieDisclaimerContextViewModel'
import type { CookieDisclaimerCategoryViewModel } from './types/CookieDisclaimerCategoryViewModel'

const Wrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  z-index: 9999;
`

const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.72);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
`

const Window = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85vh;
  margin-left: 5%;
  margin-right: 5%;
  position: relative;
  z-index: 2;

  @media only screen and (min-width: 1024px) {
    height: 85vh;
    width: 80%;
    max-width: 868px;
  }

  ${({ colorTheme, theme }) => `
    background-color: ${colorTheme.background};
    color: ${theme.primary};
  `}
`

const ScrollBody = styled.section`
  padding-bottom: 16px;
  padding-top: 16px;
  overflow-y: scroll;

  @media only screen and (min-width: 760px) and (max-width: 1024px) {
    padding-bottom: 24px;
    padding-top: 24px;
  }

  @media only screen and (min-width: 1024px) {
    padding-bottom: 32px;
    padding-top: 32px;
  }

  ${createCssHorizontalPadding}
`

const Header = styled.header`
  margin-bottom: 20px;
`

const Body = styled.main``

const Footer = styled.footer`
  height: 100%;
  max-height: 124px;
  min-height: 124px;

  ${({ theme }) => `
    background-color: ${theme.cookieFooter};
  `}

  ${createCssHorizontalPadding}

  ${mediaOnly.xs`
    height: auto;
    min-height: auto;
    max-height: none;
    padding: 1.5rem;
  `};
`

type Props = {
  onChange: (categories: CookieDisclaimerCategoryViewModel[]) => void,
  onComplete: (type: string) => void,
  onDismiss?: () => void,
  open?: boolean,
  logo?: (props: any) => React.Node,
}

const ContextProviderClient = (props: Props) => {
  const { logo, onComplete, onChange } = props
  const id = useId('CT-CookieDisclaimer')
  const ref = useFocusTrap()

  const {
    colorTheme,
    selectionChoice,
    open,
  } = React.useContext<CookieDisclaimerContextViewModel>(
    CookieDisclaimerContext,
  )

  return (
    <Wrapper
      aria-hidden={open ? 'false' : 'true'}
      aria-live={open ? 'assertive' : 'off'}
      open={open}
      ref={ref}
      role="alert"
    >
      <Background />
      <Window
        role="alertdialog"
        aria-labelledby={`CookieDialogTitle-${id}`}
        aria-describedby={`CookieDialogText-${id}`}
        colorTheme={colorTheme}
      >
        <ScrollBody>
          <Header>
            <CookieDisclaimerHeader logo={logo} />
          </Header>
          <Body>
            <CookieDisclaimerBody onCategoriesChange={onChange} />
          </Body>
        </ScrollBody>
        <Footer colorTheme={colorTheme}>
          <CookieDisclaimerFooter
            selectionChoice={selectionChoice}
            onButtonClick={onComplete}
          />
        </Footer>
      </Window>
    </Wrapper>
  )
}

ContextProviderClient.displayName = 'ContextProviderClient'
ContextProviderClient.defaultProps = {}

export default ContextProviderClient
