// @flow

import type { CookieDisclaimerCategoryViewModel } from '../types/CookieDisclaimerCategoryViewModel'

const getCategoriesHasSelected = (
  categories: CookieDisclaimerCategoryViewModel[] = [],
) => {
  let hasSelected = false

  categories.some(({ selected, necessary }) => {
    if (selected && !necessary) {
      hasSelected = true
    }

    return hasSelected
  })

  return hasSelected
}

export default getCategoriesHasSelected
