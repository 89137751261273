// @flow
import merge from 'deepmerge'

import type { CookieDisclaimerColorThemeViewModel } from '../types/CookieDisclaimerColorThemeViewModel'

const colorThemeDefaults: CookieDisclaimerColorThemeViewModel = {
  background: '#FFF',
  border: 'rgba(2, 58, 81, 0.16)',
  footer: '#e3e8ec',
  text: '#003851',
  textdimmed: '#023a51',
  field: {
    normal: '#ACC0C8',
    active: '#31A7CD',
    selected: '#31A7CD',
  },
  button: {
    primary: {
      normal: 'transparent',
      inverted: '#2fb0ab',
      normalText: '#2fb0ab',
      invertedText: '#FFF',
    },
    normal: {
      normal: 'transparent',
      inverted: '#003851',
      normalText: '#003851',
      invertedText: '#FFF',
    },
  },
}

const createColorTheme = (
  options: CookieDisclaimerColorThemeViewModel = {},
) => {
  const colorTheme = merge(colorThemeDefaults, options)

  return colorTheme
}

export default createColorTheme
