// @flow
import * as React from 'react'
import styled from 'styled-components'

import CookieDisclaimerDetailsEntry from './CookieDisclaimerDetailsEntry'

import type { CookieDisclaimerDetailsViewModel } from '../types/CookieDisclaimerDetailsViewModel'

const DetailsList = styled.dl`
  margin-bottom: 46px;

  &:last-child {
    margin-bottom: 0;
  }
`

type Props = {
  details: CookieDisclaimerDetailsViewModel[],
}

const CookieDisclaimerDetails = ({ details }: Props) => {
  return (
    <>
      {details.map(
        ({ entries }, i) =>
          entries && (
            <DetailsList key={i}>
              {entries.map((entry, i) => (
                <CookieDisclaimerDetailsEntry {...entry} key={i} />
              ))}
            </DetailsList>
          ),
      )}
    </>
  )
}

CookieDisclaimerDetails.displayName = 'CookieDisclaimerDetails'
CookieDisclaimerDetails.defaultProps = {}

export default CookieDisclaimerDetails
