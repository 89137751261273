// @flow

import * as React from 'react'
import styled from 'styled-components'

import { CookieDisclaimerContext } from '../context/CookieDisclaimerContext'

import CookieDisclaimerCategory from '../components/CookieDisclaimerCategory'
import CookieDisclaimerParagraph from '../components/CookieDisclaimerParagraph'

import type { CookieDisclaimerCategoryViewModel } from '../types/CookieDisclaimerCategoryViewModel'
import type { CookieDisclaimerContextViewModel } from '../types/CookieDisclaimerContextViewModel'
import CookieDisclaimerLinkButton from '../components/CookieDisclaimerLinkButton'

const Wrapper = styled.div``

const Text = styled.div`
  margin-bottom: 24px;
`

const Categories = styled.div``

const Category = styled.div`
  padding-bottom: 24px;
  padding-top: 23px;

  ${({ colorTheme }: Context) => `
    border-top: 1px solid ${colorTheme.border};
  `}

  &:last-child {
    ${({ colorTheme }: Context) => `
      border-top: 1px solid ${colorTheme.border};
    `}
  }
`

type Context = CookieDisclaimerContextViewModel

type Props = {
  onCategoriesChange: (categories: CookieDisclaimerCategoryViewModel[]) => void,
}

const CookieDisclaimerBody = ({ onCategoriesChange }: Props) => {
  const [showPolicy, setShowPolicy] = React.useState(false)
  const {
    categories,
    text,
    colorTheme,
    showPolicyText,
    hidePolicyText,
    cookiePolicy,
  } = React.useContext(CookieDisclaimerContext)

  return (
    <Wrapper>
      <Text>
        <CookieDisclaimerParagraph text={text || ''} noMargin={!cookiePolicy} />
        {showPolicy ? <CookieDisclaimerParagraph text={cookiePolicy} /> : null}
        {!!cookiePolicy ? (
          <CookieDisclaimerLinkButton
            onClick={() => setShowPolicy(!showPolicy)}
          >
            {showPolicy ? hidePolicyText : showPolicyText}
          </CookieDisclaimerLinkButton>
        ) : null}
      </Text>
      {categories && categories.length > 0 && (
        <Categories>
          {categories.map((category, i) => (
            <Category
              colorTheme={colorTheme}
              key={`cooke-disclaimer-category-${i}`}
            >
              <CookieDisclaimerCategory
                {...category}
                onCategoryChange={categorySelected => {
                  const categoriesClone = [...(categories || [])]
                  const categoryClone = categoriesClone[i]

                  if (categoryClone) {
                    categoriesClone[i] = {
                      ...categoryClone,
                      selected: categorySelected,
                    }
                  }

                  onCategoriesChange(categoriesClone)
                }}
              />
            </Category>
          ))}
        </Categories>
      )}
    </Wrapper>
  )
}

CookieDisclaimerBody.displayName = 'CookieDisclaimerBody'
CookieDisclaimerBody.defaultProps = {}

export default CookieDisclaimerBody
