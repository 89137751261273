// @flow
import * as React from 'react'
import styled from 'styled-components'
import { CookieDisclaimerContext } from '../context/CookieDisclaimerContext'

const Button = styled.button`
  border: none;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.75;
  outline: none;
  padding: 0;
  text-decoration: underline;

  ${({ theme }) => `
    color: ${theme.primary}
  `}

  > span {
    cursor: pointer;
    outline: none;
  }

  &:focus > span {
    box-shadow: 0 0 2px 2px #51a7e8;
  }

  &:-moz-focusring {
    outline: none;
  }
`

type Props = {
  children?: React.Node,
  onClick: () => void,
}

const CookieDisclaimerLinkButton = ({ children, ...rest }: Props) => {
  const { colorTheme } = React.useContext(CookieDisclaimerContext)
  return (
    <Button {...rest} colorTheme={colorTheme}>
      <span>{children}</span>
    </Button>
  )
}

CookieDisclaimerLinkButton.displayName = 'CookieDisclaimerLinkButton'
CookieDisclaimerLinkButton.defaultProps = {}

export default CookieDisclaimerLinkButton
