// @flow

import * as React from 'react'
import styled from 'styled-components'
import { mediaOnly } from '../../../styles/media'

import { CookieDisclaimerContext } from '../context/CookieDisclaimerContext'

const Wrapper = styled.div``

const Head = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;

  ${mediaOnly.xs`
    flex-direction: column;
  `};
`

const Logo = styled.div`
  margin-bottom: 16px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 41px;
  }
`

const Title = styled.h1`
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  letter-spacing: normal;
  line-height: 1.33;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 16px;

  ${({ theme }) => `
    color: ${theme.primary}
  `}

  ${mediaOnly.xs`
    margin-bottom: 1rem;
  `};
`

const Updated = styled.div`
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.33;
  margin: 0;
  opacity: 0.55;
  text-align: right;
  text-transform: uppercase;
  white-space: nowrap;

  ${({ theme }) => `
    color: ${theme.primary}
  `}
`

type Props = {
  logo?: (props: any) => React.Node,
}

const CookieDisclaimerHeader = ({ logo }: Props) => {
  const { title, lastUpdated, colorTheme } = React.useContext(
    CookieDisclaimerContext,
  )

  return (
    <Wrapper>
      {logo && <Logo>{logo()}</Logo>}
      <Head>
        <Title colorTheme={colorTheme}>{title}</Title>
        <Updated colorTheme={colorTheme}>{lastUpdated}</Updated>
      </Head>
    </Wrapper>
  )
}

CookieDisclaimerHeader.displayName = 'CookieDisclaimerHeader'
CookieDisclaimerHeader.defaultProps = {}

export default CookieDisclaimerHeader
